<template>
  <b-container>
    <validation-observer ref="saveKeywords" #default="{ invalid }">
      <b-form id="saveKeywords" @submit.prevent="saveKeywords(invalid)">
        <b-card class="d-flex">
          <b-row class="border rounded mx-0 mb-2 p-2 justify-content-center">
            <b-col cols="11">
              <h1 class="mb-0">Keyword Import</h1>
            </b-col>
          </b-row>
          <b-row class="border rounded p-2 justify-content-center mx-0">
            <b-col cols="3">
              <b-container class="mb-1">
                <b-row class="align-items-center">
                  <b-col cols="12">
                    <label for="Account">Account</label>
                    <b-form-group class="mb-0">
                      <validation-provider #default="{ errors }" name="Account Name" rules="required"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                        <v-select id="h-account-name" v-model="accountName" :options="accountNames"
                          @change="filterApplication()" />
                        <small class="text-danger position-absolute account-error">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="3">
              <b-container class="mb-1">
                <b-row class="align-items-center">
                  <b-col cols="12">
                    <label for="App">App</label>
                    <b-form-group class="mb-0">
                      <validation-provider #default="{ errors }" name="Package Name" rules="required">
                        <v-select id="h-pacakge-name" v-model="packageName"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :state="errors.length > 0 ? false : null"
                          :options="packageNames" />
                        <small class="
                                            text-danger
                                            position-absolute
                                            application-error
                                          ">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </b-col>
            <b-col cols="6">
              <b-container class="mb-1">
                <b-row class="align-items-center">
                  <b-col cols="12">
                    <label for="Period">Period</label>
                    <b-form-group class="mb-0">
                      <validation-provider #default="{ errors }" name="Period" rules="required">
                        <div class="d-flex align-items-center" style="gap: 15px;">
                          <b-form-datepicker :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :state="errors.length > 0 && !isSend ? false : null" name="Period" v-model="startPeriod"
                            placeholder="Start Period" class="w-50"></b-form-datepicker>
                          <b-form-datepicker :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :state="errors.length > 0 && !isSend ? false : null" name="Period" v-model="endPeriod"
                            placeholder="End Period" class="w-50"></b-form-datepicker>
                        </div>
                        <small v-if="!isSend" class="text-danger position-absolute application-error">{{ errors[0]
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>

            </b-col>
            <b-col cols="12" class="px-0 mt-2">
              <div class="drop-zone">
                <span class="drop-zone__prompt">Drop file here or click to upload</span>
                <b-form-file ref="refInputEl" accept=".csv" class="drop-zone__input" :hidden="true" plain
                  @change="keywordsFileChanged" />
              </div>
              <small class="d-block mb-1 text-danger text-center keywords_file-error"></small>
            </b-col>
            <b-col cols="12" class="mt-0 d-flex justify-content-center">
              <b-button v-ripple.400="'rgba(255, 159, 67, 0.15)'" variant="outline-success" v-b-tooltip.hover title="Save"
                type="submit" class="px-4">
                Save
                <feather-icon icon="SaveIcon" size="14" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
    <b-card>
      <b-row>
        <b-col cols="6" class="mb-1">
          <b-container>
            <b-row class="align-items-center">
              <b-col cols="4" class="text-center">
                <span class="mb-0">Filter By Account :</span>
              </b-col>
              <b-col cols="8">
                <b-form-group class="mb-0">
                  <validation-provider #default="{ errors }" name="Filter By Account"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
                    <v-select id="h-account-name" v-model="filterByAccountName" :options="filterByAccountNames"
                      @change="filterImportsHistoryByAccount()" />
                    <small class="text-danger account-error">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-col>

        <b-col md="6" class="mb-1">
          <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
            class="mb-0 d-flex align-items-center">
            <b-input-group>
              <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(actions)="data">
              <b-button-group>
                <b-button v-if="data.item.import_history_ID" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-danger" class="btn-icon rounded-circle" v-b-tooltip.hover title="Delete"
                  @click="confirmdeleteImport(data.item.import_history_ID)">
                  <feather-icon icon="Trash2Icon" size="20" />
                </b-button>
              </b-button-group>
            </template>
            <template #cell(period)="data">
              <p v-if="data.item.created_at_formated">
                {{ data.item.start_period + " To " + data.item.end_period }}
              </p>
            </template>
            <template #cell(Imported)="data">
              <span class="d-block text-center" v-if="data.item.created_at_formated"><b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-success" class="btn-icon rounded-circle"
                  v-b-tooltip.hover title="Imported">
                  <feather-icon icon="CheckCircleIcon" size="20" /> </b-button></span>
              <span class="d-block text-center" v-else><b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-danger" class="btn-icon rounded-circle" v-b-tooltip.hover title="Not Imported">
                  <feather-icon icon="XIcon" size="20" /> </b-button></span>
            </template>
          </b-table>
        </b-col>
        <b-col cols="6">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-25" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-card>
    <b-modal id="modal-deleteing-app" ok-variant="danger" ok-title="Confirm" modal-class="modal-deleteing-app" centered
      title="Deleting App!" @ok="deleteImport">
      <b-card-text>Are you sure you want to delete this app?</b-card-text>
    </b-modal>
  </b-container>
</template>

<script>
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BModal,
  BCard,
  BFormSelect,
  BTable,
  BInputGroup,
  BFormTextarea,
  BFormInput,
  BFormGroup,
  BFormFile,
  BImg,
  BForm,
  BInputGroupAppend,
  BCardText,
  BButtonGroup,
  VBTooltip,
  BPagination,
  BButton,
  VBHover,
  BContainer,
  BFormInvalidFeedback,
  BAspect,
  BFormDatepicker,
} from "bootstrap-vue";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";
import axios from "@axios";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormTextarea,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    BImg,
    BFormSelect,
    BInputGroupAppend,
    BForm,
    BTable,
    BCardText,
    BInputGroup,
    BModal,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    vSelect,
    BAspect,
    editor: Editor,
    BFormInvalidFeedback,
    BFormDatepicker
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-hover": VBHover,
    Ripple,
  },
  data() {
    return {
      _id: null,
      packageName: null,
      accountName: null,
      packageNames: [],
      accountNames: [],
      startPeriod: null,
      endPeriod: null,

      isSend: false,
      filterByAccountName: null,
      filterByAccountNames: [],
      keywordsFile: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      items: [],
      filterOn: [],
      fields: [
        {
          key: "import_history_ID",
          label: "#",
        },
        {
          key: "title",
          label: "Application",
        },
        {
          key: "account_name",
          label: "Account",
        },
        {
          key: "period",
          label: "period",
        },
        {
          key: "created_at_formated",
          label: "Imported at",
        },
        "Imported",
        "Actions",
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.showAllAccounts();
    this.getHistory();
    // Set the initial number of items
    // this.totalRows = this.items.length;

    // ###################################################################
    document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
      const dropZoneElement = inputElement.closest(".drop-zone");

      dropZoneElement.addEventListener("click", (e) => {
        inputElement.click();
      });

      inputElement.addEventListener("change", (e) => {
        if (inputElement.files.length) {
          updateThumbnail(dropZoneElement, inputElement.files[0]);
        }
      });

      dropZoneElement.addEventListener("dragover", (e) => {
        e.preventDefault();
        dropZoneElement.classList.add("drop-zone--over");
      });

      ["dragleave", "dragend"].forEach((type) => {
        dropZoneElement.addEventListener(type, (e) => {
          dropZoneElement.classList.remove("drop-zone--over");
        });
      });

      dropZoneElement.addEventListener("drop", (e) => {
        e.preventDefault();

        if (e.dataTransfer.files.length) {
          inputElement.files = e.dataTransfer.files;
          this.keywordsFile = e.dataTransfer.files[0];
          updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
        }

        dropZoneElement.classList.remove("drop-zone--over");
      });
    });

    function updateThumbnail(dropZoneElement, file) {
      let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

      // First time - remove the prompt
      if (dropZoneElement.querySelector(".drop-zone__prompt")) {
        dropZoneElement.querySelector(".drop-zone__prompt").remove();
      }

      // First time - there is no thumbnail element, so lets create it
      if (!thumbnailElement) {
        thumbnailElement = document.createElement("div");
        thumbnailElement.classList.add("drop-zone__thumb");
        dropZoneElement.appendChild(thumbnailElement);
      }

      thumbnailElement.dataset.label = file.name;

      // Show thumbnail for image files
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
        };
      } else {
        thumbnailElement.style.backgroundImage = null;
      }
    }
    // ###################################################################
  },
  watch: {
    accountName(value) {
      if (value) this.filterApplication(value.value);
      else this.packageNames = [];
    },
    filterByAccountName(value) {
      value ? this.getImportHistoryByAccount(value.value) : this.getHistory();
    },
  },
  methods: {
    getImportHistoryByAccount(account) {
      axios
        .get(`/get-import-history-by-account/${account}`)
        .then((response) => {
          if (response.data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          }
          if (response.data.status === "success") {
            if (response.data.count > 0) {
              this.items = response.data.histories;
              this.totalRows = this.items.length;
            } else {
              this.items = null;
              this.totalRows = 0;
            }
          }
        })
        .catch((exception) => {
          console.error(exception);
        });
    },
    async getHistory() {
      await axios
        .post("/get-import-history", {
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
        .then((response) => {
          if (response.data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          }
          if (response.data.status === "success") {
            if (response.data.count > 0) {
              this.items = response.data.histories;
              this.totalRows = this.items.length;
            } else {
              this.items = null;
              this.totalRows = 0;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    filterApplication(accountId) {
      axios
        .post(`/get-apps-by-account`, {
          accountId,
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
        .then((response) => {
          if (response.data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          }
          if (response.data.status === "success") {
            this.packageNames = [];
            if (response.data.count !== 0) {
              response.data.apps.forEach((el) => {
                this.packageNames.push({
                  label: el.title,
                  value: el.id,
                });
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    confirmdeleteImport(id) {
      this._id = id;
      this.$root.$emit("bv::show::modal", "modal-deleteing-app");
    },
    async saveKeywords(i) {
      const applicationError = document.querySelector(".application-error"),
        accountError = document.querySelector(".account-error"),
        keywordsFileError = document.querySelector(".keywords_file-error");
      if (this.packageName && this.keywordsFile) {
        let fileData = new FormData();

        if (this.packageName) {
          fileData.append("application", this.packageName.value);
        }
        if (this.accountName) {
          fileData.append("account", this.accountName.value);
        }
        if (this.keywordsFile) {
          fileData.append("keywords_file", this.keywordsFile);
        }
        if (this.startPeriod) {
          this.isSend = true;
          fileData.append("start_period", this.startPeriod);
        }
        if (this.endPeriod) {
          fileData.append("end_period", this.endPeriod);
          this.isSend = true;
        }
        await axios
          .post("/store-keywords", fileData)
          .then(({ data }) => {
            if (data.status == "Unauthorized") {
              removeLocalStorageItems();
              router.push({ name: "auth-login" });
            }
            if (data.status === "success") {
              this.getHistory();
              applicationError.textContent = "";
              accountError.textContent = "";
              keywordsFileError.textContent = "";
              this.showToast("success", "SaveIcon", "Done", data.message);
              this.endPeriod = null;
              this.startPeriod = null;
              this.packageName = null;
              this.packageNames = [];
              this.accountName = null;
            } else if (data.status === "failed") {
              if (data.errors) {
                keywordsFileError.textContent = data.errors.keywords_file;
                accountError.textContent = data.errors.account;
                applicationError.textContent = data.errors.application;
              } else {
                applicationError.textContent = "";
                accountError.textContent = "";
                keywordsFileError.textContent = "";
              }
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Error",
                data.message
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (!this.packageName) {
          applicationError.textContent = "The app field is required";
        }
        if (!this.accountName) {
          accountError.textContent = "The account field is required";
        }
        if (!this.keywordsFile) {
          keywordsFileError.textContent = "The Keywords File Field is Required";
        }
      }
    },
    deleteImport() {
      axios
        .post("/delete-import-history", {
          import_id: this._id,
        })
        .then(({ data }) => {
          if (data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status == "success") {
            this.showToast("success", "TrashIcon", "Done", data.message);
            this.getHistory();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async showAllAccounts() {
      await axios
        .post("/get-accounts", {
          user_token: JSON.parse(localStorage.getItem("accessToken")),
        })
        .then((response) => {
          if (response.data.status == "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          }
          this.accountNames = [];
          this.filterByAccountNames = [];
          response.data.accounts.forEach((el) => {
            this.accountNames.push({ label: el.account_name, value: el.id });
            this.filterByAccountNames.push({
              label: el.account_name,
              value: el.id,
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    keywordsFileChanged(e) {
      this.keywordsFile = e.target.files[0];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.drop-zone {
  width: 95%;
  margin: auto;
  height: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 2px dashed #4b4b4b;
  border-radius: 10px;

  &--over {
    border-style: solid;
  }

  &__input {
    display: none;
  }

  &__prompt {
    user-select: none;
  }

  &__thumb {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #cccccc;
    background-size: cover;
    position: relative;

    &::after {
      content: attr(data-label);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 5px 0;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.75);
      font-size: 14px;
      text-align: center;
    }
  }
}

.vs__selected {
  user-select: none;
}
</style>
